import React from "react";
import { PageContainer, PageHeader } from "../components/common";

const Page404 = () => (
  <PageContainer>
    <PageHeader>
      <h1>404 Page Not Found</h1>
      <h4>
        If you think this was our mistake, send us an email: support@canvis.app.
      </h4>
      <h4>
        Learn more about our platform ->{" "}
        <a href="https://about.canvis.app">about.canvis.app</a>
      </h4>
      <h4>
        Contribute to maps or create a project ->{" "}
        <a href="https://canvis.app">canvis.app</a>
      </h4>
    </PageHeader>
  </PageContainer>
);

export default Page404;
